import { Outlet } from "react-router-dom";
import LeftMenu from "./LeftMenu/LeftMenu";
import Header from "./Header/Header";
import { Toaster } from "react-hot-toast";

const Layout = () => {
    return (
        <>
            <LeftMenu />
            <main className="ease-soft-in-out xl:ml-[17.125rem] relative h-full max-h-screen rounded-xl transition-all duration-200">
                <div className="w-full px-6 py-6 mx-auto">
                    <Header />
                    <div className="py-1 my-4">
                        <Outlet />
                    </div>
                </div>
            </main>
            <Toaster />
        </>
    )
}

export default Layout;