import { createBrowserRouter, Navigate } from "react-router-dom"
import NotFoundPage from "../pages/NotFoundPage"
import { lazy } from "react"
import { ROUTES } from "../shared/constants/routePaths";
import Layout from "../components/Layout";
import { PrivateRoute } from "../shared/PrivateRoute";
import { UserRoleType } from "../shared/enums/userRole";

const LoginPage = lazy(() => import('../pages/login/LoginPage'));
const Dashboard = lazy(() => import('../pages/dashboard/Dashboard'));
const Company = lazy(() => import('../pages/company/Company'));
const AddCompany = lazy(() => import('../pages/company/AddCompany'));

export const useGetRouter = () => {
    return createBrowserRouter([
        {
            path: '*',
            element: <NotFoundPage />
        },
        {
            path: `/${ROUTES.login}`,
            element: (
                <PrivateRoute roles={[UserRoleType.admin, UserRoleType.editor, UserRoleType.user]}>
                    <LoginPage />
                </PrivateRoute>
            )
        },
        {
            path: '',
            element: <Layout />,
            children: [
                {
                    path: '',
                    element: <Navigate to={`/${ROUTES.dashboard}`} replace />
                },
                {
                    path: `/${ROUTES.dashboard}`,
                    element: (
                        <PrivateRoute roles={[UserRoleType.admin, UserRoleType.editor]}>
                            <Dashboard />
                        </PrivateRoute>
                    )
                },
                {
                    path: `/${ROUTES.company}`,
                    element: (
                        <PrivateRoute roles={[UserRoleType.admin]}>
                            <Company />
                        </PrivateRoute>
                    )
                },
                {
                    path: `/${ROUTES.addCompany}`,
                    element: (
                        <PrivateRoute roles={[UserRoleType.admin]}>
                            <AddCompany />
                        </PrivateRoute>
                    )
                },
                {
                    path: `/${ROUTES.addCompanyWithId}`,
                    element: (
                        <PrivateRoute roles={[UserRoleType.admin]}>
                            <AddCompany />
                        </PrivateRoute>
                    )
                },
            ]
        }
    ])
};